import maplibregl from 'maplibre-gl';

export default class CustomScaleControl {
	constructor(options) {
		this._map = null;
		this._container = null;
		this._scale = null;
		this.options = options || {};
		this._positionFunction = null;
	}
	setVisibility(isVisible) {
		if (this._container) {
		this._container.style.display = isVisible ? 'block' : 'none';
		}
	}
	onAdd(map) {
		this._map = map;
		this._container = document.createElement('div');
		this._container.className = 'maplibregl-ctrl custom-scale-control';
		this._container.id = 'custom-scale-control';
		this._container.style.cursor = 'inherit !important';
		this._container.style.position = 'absolute';
		this._container.style.pointerEvents = 'none !important';

		this._scale = new maplibregl.ScaleControl({
			maxWidth: this.options.maxWidth || 100,
			unit: this.options.unit || 'metric',
		});

		this._scale.onAdd(map);
		this._container.appendChild(this._scale._container);

		this._updatePosition();

		// マップのリサイズイベントをリッスンして位置を更新
		this._map.on('resize', this._updatePosition.bind(this));
		this._map.on('zoom', this._updatePosition.bind(this));

//		if(document.getElementById('custom-scale-control')) document.getElementById('custom-scale-control').style.pointerEvents = 'none !important';
//		if(this._container) this._container.style.pointerEvents = 'none !important';

  //      this._addNoSelectClass();

		return this._container;
	}

	onRemove() {
		this._container.parentNode.removeChild(this._container);
		this._map.off('resize', this._updatePosition.bind(this));
		this._map.off('zoom', this._updatePosition.bind(this));
		this._map = undefined;
	}

	setPosition(positionFunction) {
		this._positionFunction = positionFunction;
		this._updatePosition();
	}

	update() {
		this._updatePosition();
		this._updatePosition.bind(this);
	}

	_updatePosition() {
		if (this._positionFunction && this._map) {
			const mapContainer = this._map.getContainer();
			const mapWidth = mapContainer.offsetWidth;
			const mapHeight = mapContainer.offsetHeight;

			const scaleWidth = this._container.offsetWidth;
			const scaleHeight = this._container.offsetHeight;

			const topRightControl = mapContainer.querySelector('.maplibregl-ctrl-top-right');
			const topRightRect = topRightControl.getBoundingClientRect();
			const correctionX = topRightRect.left;
			const correctionY = topRightRect.top;

			const position = this._positionFunction(mapWidth, mapHeight, scaleWidth, scaleHeight,correctionX,correctionY);

			this._container.style.left = `${position.x}px`;
			this._container.style.top = `${position.y}px`;

		}
	}
/*	_addNoSelectClass() {
        if (this._container) {
            const scaleElement = this._container.querySelector('.maplibregl-ctrl-scale');
            if (scaleElement) {
                scaleElement.classList.add('no-mouse-action');
            }
        }
    }*/
}