const resources = {
	en: {
		translation: {
			"App_Title": "CollaboMap",

			"Login": "Sign in to the CollaboMap",
			"Login message": "You need to log in to use.In the case of anonymous login, just press the button.The account disappears in 30 days.You can use it forever if you log in with the Google account.It is considered to be a login, or consent to the Terms of Use.",
			"Anonymous": "Anonymous",
			"Anonymous login": "Anonymous Sign in",
			"Login on Google": "Sign in on Google",
			"Terms and privacy policy": "Terms and privacy policy",
			"confirm_Logout": "Is it okay to Sign out?",
			"confirm_Logout_Anonymous": "Is it okay to log out?In the case of anonymous login, you cannot log in with the same anonymous account again when you log out.Do you really log out?",

			"Signed in": "Signed in",
			"Signed out": "Signed out",

			"Add a marker": "Add a marker",
			"Marker name": "Marker name",
			"Elevation": "Elevation",
			"Coordinate": "Coordinate",
			"latitude": "latitude",
			"longitude": "longitude",
			"Accuracy": "Accuracy",
			"ShootingDateTime": "Shooting",
			"FileName": "File Name",
			"radius":"radius",
			"Area":"Area",
			"Perimeter":"Perimeter",
			"Distance":"Dist",
			"Time":"Time",
			"ascent":"Δ(+)",
			"descent":"Δ(-)",


			"min":"min",

			"Note": "Note",
			"Icon": "Icon",
			"Cancel": "Cancel",
			"Close": "Close",
			"Add": "Add",
			"Make": "Make",
			"Save": "Save",
			"edit": "Edit",
			"move": "Move",
			"copy": "Replication",
			"copyB": "to clipboard",
			"delete": "Delete",
			"Search": "Search",
			"Send": "Send",
			"Apply": "Apply",
			"Error": "Error",

			"Name": "Name",
			"Min Zoom": "Minimum zoom level",
			"Max Zoom": "Maximum zoom level",
			"Copylight": "Copylight",
			"ReferencePoint": "Reference point",
			"NW": "Northwest",
			"SW": "Southwest",
			"Color conversion": "Color conversion",
			"do not": "do not",
			"white to red": "White → red",
			"Use proxy":"Use Proxy (usually off)",

			"Tags":"Tags",
			"Thumbnail":"Thumbnail",
			"Create thumbnail":"Create thumbnail",
			"Thumbnail Created":"Thumbnail Created",
			"Thumbnail has been created and saved.":"Thumbnail has been created and saved.",

			"Searchable":"Open to the public",
			"Copyable":"Copyable",
			"Reset":"Reset",
			"Rotate":"Rotate",

			"Markers":"Markers",
			"Tracks":"Tracks",
			"Photograph":"photograph",
			"Files":"Files",
			"File":"File",
			"Shape":"Shape",
			"Draw":"Draw",
			"chat": "Comment",

			"input_userinfo": "Set your profile",
			"input_name": "name",
			"input_color": "Theme color",

			"new_projects": "Make a new Map",
			"edit_projects": "Change the map settings",

			"exist_projects": "Existing Maps",
			"Favorite_projects":"Favorite Maps",
			"Recent_projects":"Recent maps(Open to the public)",
			"Popularity_projects":"Popular map",
			"Recommended_projects":"Recommended map",

			"My_Projects":"My Maps",
			"Recently seen":"Map of other users recently seen",
			"Title": "Title",
			"Owner": "Owner",

			"Layer Settings": "Layer Settings",
			"Overlay": "Overlay",
			"Custom layer settings": "Custom layer settings",

			"Shared range": "Shared range",
			"range_private": "only me",
			"range_vieweable": "Only browsing",
			"range_Joint_editing": "Joint editing",

			"Select a Color": "Line setting palette",
			"line_width":"Line width",
			"Current color":"Current color",
			"Opacity":"Opacity",
			"Choose from the pallet":"Please choose from the palette below",

			"freehand_drawing": "Free hand drawing",
			"freehand_eraser" : "Free hand eraser ",
			"palette": "Palette",
			"sign_out": "Sign out",
			"undo": "Undo",

			"error_empty_name":"The name is blank.Please enter",

			"Previous":"Previous",
			"Next":"Next",
			"goBack":"go back",
			"AddMemo":"AddMemo",

			"expandScreenButton": "All screen",
			"currentLocationButton": "Current location",
			"addMarketButton": "Marker Added",
			"uploadButton": "File Upload",
			"chatButton": "Chat",
			"searchButton": "Search",
			"penButton": "Free Hand Drawing",
			"shapeButton": "figure",
			"paletteButton": "Pallet",
			"eraserButton": "Eraser",
			"undoButton": "Undo",
			"ScreenShot":"Screenshot",

			"lineButton":"Line",
			"measurementButton":"Measurement",
			"circleButton":"Circle",
			"polygonButton":"Polygon",

			"ExitFullscreen":"Return",
			"Full_screen": "Full screen",
			"Add_Favorite": "Add to Favorites",
			"Report": "Report",
			"Share": "Sharing",
			"Camera": "Camera",
			"Setting":"Setting",
			"Data_list":"Data list",
			"LayerSetting":"Map layer settings",
			"Print": "Printing",
			"PaperSize": "Paper size",
			"ZoomLevel": "Zoom Level",
			"MagneticNorthLine": "Magnetic Kita Line",
			"CopyProject": "Copy the Map",

			"removeConfirm": "Delete {1}. Is that okay?",
			"reConfirm": "Are you really happy?",
			"rereConfirm": "Are you really happy ??",
			"deleteed": "deleted",
			"searchResult": "Search results",
			"noSearchResult": "The search result was 0",
			"makeLinkInfo08": "Display the position in Geographica",
			"makeLinkInfo09": "Geographica Marker added",
			"copiedAddress": "Copy",
			"NoPermissions": "Error: This Map has been deleted for a network. Wait for a while or check the network connection.",
			"Reposition": "Restoration",
			"Copied!": "Copied!",
			"Copy URL": "Copy URL",
			"Search Location": "Search Location",
			"Enter location":"Enter location",
			"Share Map": "Share Map",
			"Enter tags separated by spaces or commas":"Enter tags separated by spaces or commas",

			"Base Map": "Base map",
			"kokudo": "JP Kokudo",
			"osm": "Open street map",
			"otm": "Open pomap",
			"Terrain Exaggeration":"Terrain Exaggeration",

			"DeletePhotoConfirmation": "Is it okay to delete this photo?",
			"no gps photo":"Web browser restrictions or photos do not contain location information and the position is unknown.For the time being, it was placed in the center of the screen.If the position is wrong, tap and press the [Move] button to move.",

			"Type a comment...":"Enter Comment ...",
			"No Map": "no map...",
			"Zip download": "ZIP download",
			"download": "DL",

			"Uploading...":"Uploading ...",
			"max_data_message":"Total registration restriction of data such as markers, photos, files.If you want to add, delete the marker.",
			"max_data_message_B":"All files could not be uploaded due to the total registration restriction of data such as markers, photos, and files.If you want to add, delete unnecessary markers.",

			"Genre":"Category",
			"Select a genre":"Select a genre",
			"Please select a genre":"Please select a genre",
			"Enter the title":"Please enter the title",
			"More...":"More...",

			"MyPage":"My Page",
			"TopPage":"Top page",

			"Genre_00": "Outdoor",
			"Genre_01": "Rescue / Search",
			"Genre_02": "Incident / accident",
			"Genre_03": "Medical",
			"Genre_04": "Public / Infrastructure",
			"Genre_05": "Travel / Sightseeing",
			"Genre_06": "Pilgrimage",
			"Genre_07": "Transportation",
			"Genre_08": "Education / Academic",
			"Genre_09": "Research / Survey",
			"Genre_10": "History / Historic Site",
			"Genre_11": "Religion",
			"Genre_12": "Art",
			"Genre_13": "Leisure",
			"Genre_14": "Gourmet",
			"Genre_15": "Industry",
			"Genre_16": "architecture",
			"Genre_17": "Financial / Real Estate",
			"Genre_18": "Politics / Administration",
			"Genre_19": "Others / Uncategorized",

			"total": "Total",

			"User Settings": "User Settings",
			"Personal Settings": "Personal Settings",
			"Theme Color": "Theme Color",
			"Handedness": "Handedness",
			"Left-handed": "Left",
			"Right-handed": "Right",
			"Walking speed":"Walking speed",
			"Walking speed 01":"Late (35m/min)",
			"Walking speed 02":"Standard (70m/point)",
			"Walking speed 03":"Fast (105m/minutes)",
			"Walking speed 04":"Super fast (140m/min)",
			"Walking speed 05":"Further faster (210m/min)",

			"Default Map Creation Settings": "Default Map Creation Settings",
			"Enter your name": "Enter your name",
			"Failed to update settings": "Failed to update settings",

			"ConfirmCopyMap": "Copy {1} as your own map.Are you sure?",
			"ConfirmMoveCopyMap": "Go to the copied map.",
			"waitingMessage": "Please wait for a while ...",
			"Copy not possible": "This map is not copied.",

			"confirmCopyClipboard": "Copy to the clipboard",
			"Account permanent": "Account permanent",

			"Succeeded Account permanent": "I succeeded in permanent account",
			"Error Account permanent": "The permanent account failed:",

			"Screenshot Create":"Screenshot",
			"Screenshot has been created.":"If you want to save, save the above image.I put it in the clipboard.",

			"message_layer_kokudo_slope":"Avalanche slope is an assessment of the risk of avalanches based on the angle of the slope, and is created by the Geospatial Information Authority of Japan. The order of avalanche risk is red → orange → yellow. \nSlope of 30 to 35 degrees: orange, 35 to 45 degrees: red, 45 to 55 degrees: orange, 55 to 60 degrees: yellow.",
			"message_layer_kokudo_slopeB":"Avalanche slope type B is displayed by combining the avalanche slope and slope map from the Geospatial Information Authority of Japan in Collabomap. The slope is estimated from the shade of the slope map. The slope increases in the order of yellow → orange → red → brown → gray. \nSlope 25-30 degrees: yellow, 30-35 degrees: orange, 36-45 degrees: red, 45-54 degrees: brown, 55 degrees or more: gray. \nOrange and red conform to the original avalanche slope, but yellow, brown, and gray are estimated from the shade of the slope map, so accuracy cannot be guaranteed.",
			"message_layer_kokudo_slopemapB":"The Slope division map map is a color-coded map created by estimating the angle from the shade of the slope map of the Geospatial Information Authority of Japan and processing it in Collabomap.\nSlope 0-10 degrees: white, 11-20 degrees: light green, 21-30 degrees: green, 31-40 degrees: blue-purple, 41-50 degrees: purple, 51-60 degrees: red-purple, 61-70 degrees: red, 71 degrees or more: gray.\nThe white and green areas are the ranges where a person can climb up and down comfortably on foot, and if the slope is greater than this, the risk of falling increases. Also, there may be cliffs or waterfalls in the white and green areas. The colors are simply estimated angles from the slope map.",
		}

	},
	ja: {
		translation: {
			"App_Title": "コラボマップ",

			"Login": "コラボマップにログイン",
			"Login message": "利用にはログインが必要です。匿名ログインの場合はボタンを押すだけ。30日でアカウントは消えます。Googleアカウントでログインすると永続的に使えます。ログインすなわち利用規約への同意とみなします。",
			"Anonymous": "匿名",
			"Anonymous login": "匿名ログイン",
			"Login on Google": "Googleでログイン",
			"Terms and privacy policy": "利用規約とプライパシーポリシー",
			"confirm_Logout": "ログアウトしてよろしいですか？",
			"confirm_Logout_Anonymous": "ログアウトしてよろしいですか？匿名ログインの場合、ログアウトすると再度同じ匿名アカウントでログインすることは出来ません。本当にログアウトしますか？",
			"Signed in": "ログインしました",
			"Signed out": "ログアウトしました",

			"Add a marker": "マーカーを追加",
			"Marker name": "マーカー名",
			"Elevation": "標高",
			"Coordinate": "座標",
			"latitude": "緯度",
			"longitude": "経度",
			"Accuracy": "精度",
			"ShootingDateTime": "撮影",
			"FileName": "ファイル名",
			"radius":"半径",
			"Area":"面積",
			"Perimeter":"外周",
			"Distance":"距離",
			"Time":"時間",
			"ascent":"Δ(+)",
			"descent":"Δ(-)",
			"min":"分",

			"Note": "メモ",
			"Icon": "アイコン",
			"Cancel": "キャンセル",
			"Close": "閉じる",
			"Add": "追加",
			"Make": "作る",
			"Save": "保存",
			"edit": "編集",
			"move": "移動",
			"copy": "即複製",
			"copyB": "コピー",
			"delete": "削除",
			"Search": "検索",
			"Send": "送信",
			"Apply": "適用",
			"Error": "エラー",

			"Name": "名前",
			"Min Zoom": "最小ズームレベル",
			"Max Zoom": "最大ズームレベル",
			"Copylight": "著作権表示",
			"ReferencePoint": "基準点",
			"NW": "北西",
			"SW": "南西",
			"Color conversion": "色変換",
			"do not": "しない",
			"white to red": "白→赤",
			"Use proxy":"プロクシを使う（通常はオフ）",

			"Tags":"タグ",
			"Thumbnail":"サムネイル",
			"Create thumbnail":"サムネイルを作成",
			"Thumbnail Created":"サムネイルを作りました",
			"Thumbnail has been created and saved.":"サムネイルを作って保存しました",

			"Searchable":"一般公開",
			"Copyable":"コピー可能",
			"Reset":"リセット",
			"Rotate":"回転",

			"Markers":"マーカー",
			"Tracks":"トラック",
			"Photograph":"写真",
			"Files":"ファイル",
			"File":"ファイル",
			"Shape":"図形",
			"Draw":"手書き",
			"chat": "コメント",

			"input_userinfo": "ユーザー情報を設定",
			"input_name": "名前",
			"input_color": "テーマカラー",

			"new_projects": "新しい地図を作る",
			"edit_projects": "地図の設定を変更",
			"exist_projects": "既存の地図",
			"Favorite_projects":"お気に入りの地図",

			"Recent_projects":"最近の地図（全ユーザーが見られます）",
			"Popularity_projects":"人気の地図",
			"Recommended_projects":"おすすめの地図",

			"My_Projects":"マイ地図",
			"Recently seen":"最近見た他ユーザーの地図",
			"Title": "タイトル",
			"Owner": "オーナー",

			"Layer Settings": "地図レイヤーの設定",
			"Overlay": "オーバーレイ",
			"Custom layer settings": "カスタムレイヤ設定",

			"Shared range": "共有範囲",
			"range_private": "プライベート",
			"range_vieweable": "閲覧限定",
			"range_Joint_editing": "共同編集",

			"Select a Color": "線の設定パレット",
			"line_width":"線の太さ",
			"Current color":"現在の色",
			"Opacity":"透明度",
			"Choose from the pallet":"下のパレットから選んでください",

			"freehand_drawing": " フリーハンド描画 ",
			"freehand_eraser" : "フリーハンド消ゴム",
			"palette": "パレット",
			"sign_out": "ログアウト",
			"undo": "一つ戻す",

			"error_empty_name":"名前が空欄です。入力してください",

			"expandScreenButton":"全画面",
			"currentLocationButton":"現在地",
			"addMarketButton":"マーカー追加",
			"uploadButton":"ファイルアップロード",
			"chatButton":"チャット",
			"searchButton":"検索",
			"penButton":"フリーハンド描画",
			"shapeButton":"図形",
			"paletteButton":"パレット",
			"eraserButton":"消しゴム",
			"undoButton":"やり直し",
			"ScreenShot":"スクリーンショット",

			"lineButton":"直線",
			"measurementButton":"計測",
			"circleButton":"円",
			"polygonButton":"多角形",

			"Previous":"前へ",
			"Next":"次へ",
			"goBack":"戻る",
			"AddMemo":"メモを追加",

			"ExitFullscreen":"戻す",
			"Full_screen":"全画面",
			"Add_favorite":"お気に入りに追加",
			"report":"通報する",
			"Share":"共有",
			"camera":"カメラ",
			"Setting":"設定",
			"Data_list":"データ一覧",
			"LayerSetting":"地図レイヤー設定",
			"Print":"印刷",
			"PaperSize":"紙のサイズ",
			"ZoomLevel":"ズームレベル",
			"MagneticNorthLine":"磁北線",
			"CopyProject":"地図をコピー",

			"removeConfirm":"{1}を削除します。よろしいですか？",
			"reConfirm":"本当によろしいですか？",
			"rereConfirm": "本当によろしいんですね？？",
			"deleteed":"削除しました",
			"searchResult": "検索結果",
			"noSearchResult": "検索結果は0件でした",
			"makeLinkInfo08": "Geographicaで位置を表示",
			"makeLinkInfo09": "Geographicaマーカー追加",
			"copiedAddress": "コピーしました",
			"NoPermissions": "エラー：ネットワークに繋がっていないか、この地図は削除されました。しばらく待つかネットワークの接続を確認してください。",

			"Reposition": "復元",
			"Copied!": "コピーしました",
			"Copy URL": "URLをコピー",
			"Search Location": "地名検索",
			"Enter location":"地名・山名などを入力",
			"Share Map": "この地図を共有",
			"Enter tags separated by spaces or commas":"スペースかカンマ区切りでタグを入力",

			"Base Map": "ベース地図",
			"kokudo": "国土地理院",
			"osm": "オープンストリートマップ",
			"otm": "オープントポマップ",
			"Terrain Exaggeration":"高低差の誇張倍率",

			"DeletePhotoConfirmation": "この写真を削除してもよろしいですか？",
			"no gps photo":"Webブラウザの制限または、写真に位置情報が入っておらず位置が不明です。とりあえず画面中央に配置しました。位置が間違っている場合は、タップして[移動]ボタンを押して移動してください。",

			"Type a comment...":"コメントを入力…",
			"No Map": "地図がありません…",
			"Zip download": "ZIPでダウンロード",
			"download": "DL",

			"Uploading...":"アップロード中...",
			"max_data_message":"マーカー、写真、ファイルなどデータの合計登録制限です。追加したい場合は要らないマーカーなどを削除してください。",
			"max_data_message_B":"マーカー、写真、ファイルなどデータの合計登録制限により、全てのファイルをアップロード出来ませんでした。追加したい場合は要らないマーカーなどを削除してください。",

			"Genre":"ジャンル",
			"Select a genre":"ジャンルを選択してください",
			"Please select a genre":"ジャンルを選択してください",
			"Enter the title":"タイトルを入力してください",
			"More...":"更に読み込む...",

			"MyPage":"マイページ",
			"TopPage":"トップページ",

			"Genre_00":"アウトドア",
			"Genre_01":"救助・捜索",
			"Genre_02":"事件・事故",
			"Genre_03":"医療",
			"Genre_04":"公共・インフラ",
			"Genre_05":"旅行・観光",
			"Genre_06":"聖地巡礼",
			"Genre_07":"交通",
			"Genre_08":"教育・学問",
			"Genre_09":"研究・調査",
			"Genre_10":"歴史・史跡",
			"Genre_11":"宗教",
			"Genre_12":"芸術",
			"Genre_13":"レジャー",
			"Genre_14":"グルメ",
			"Genre_15":"産業",
			"Genre_16":"建築",
			"Genre_17":"金融・不動産",
			"Genre_18":"政治・行政",
			"Genre_19":"その他・未分類",

			"total": "合計",

			"User Settings": "ユーザー設定",
			"Personal Settings": "個人設定",
			"Theme Color": "テーマカラー",
			"Handedness": "利き手",
			"Left-handed": "左",
			"Right-handed": "右",
			"Walking speed":"歩行速度(計測の時間計算で使用)",
			"Walking speed 01":"遅め(35m/分)",
			"Walking speed 02":"標準(70m/分)",
			"Walking speed 03":"速め(105m/分)",
			"Walking speed 04":"超速め(140m/分)",
			"Walking speed 05":"更に速め(210m/分)",

			"Default Map Creation Settings": "地図作成のデフォルト設定",
			"Enter your name": "名前を入力してください",
			"Failed to update settings": "設定の更新に失敗しました",

			"ConfirmCopyMap": "{1}を自分の地図としてコピーします。よろしいですか？",
			"ConfirmMoveCopyMap": "コピーした地図に移動します。",
			"Copy not possible": "この地図はコピー不可です。",

			"waitingMessage": "処理中です。そのままお待ち下さい...",
			"confirmCopyClipboard": "クリップボードにコピーしました",
			"Account permanent": "アカウントを永続化する",
			"Succeeded Account permanent": "アカウントの永続化に成功しました",
			"Error Account permanent": "アカウントの永続化に失敗しました:",

			"Screenshot Create":"スクリーンショット",
			"Screenshot has been created.":"保存したければ上の画像を保存してください。クリップボードにも入れておきました。",

			"message_layer_kokudo_slope":"雪崩傾斜は斜面の角度から雪崩のリスクを評価したもので国土地理院が作成しています。雪崩のリスクが高い順に赤→オレンジ→黄色です。\n傾斜30～35度：オレンジ、35～45度：赤、45～55度：オレンジ、55～60度：黄色で表示します。",
			"message_layer_kokudo_slopeB":"雪崩傾斜タイプBは国土地理院の雪崩傾斜と傾斜量図をコラボマップ内で合成、表示しています。傾斜は傾斜量図の濃淡から推定しています。黄色→オレンジ→赤→茶色→グレーの順で傾斜が増します。\n傾斜25～30度：黄色、30～35度：オレンジ、36～45度：赤、45～54度：茶色、55度以上：グレーで表示します。\nオレンジと赤は元の雪崩傾斜に準拠しますが、黄色、茶色、グレーは傾斜量図の濃淡から推定したものなので精度は保証しません。",
			"message_layer_kokudo_slopemapB":"色別傾斜区分図は国土地理院の傾斜量図の濃淡から角度を推定して、コラボマップ内で色分け処理したものです。\n傾斜0～10度：白抜き、11～20度：薄い緑、21～30度：緑、31～40度：青紫、41～50度：紫、51～60度：赤紫、61〜70度：赤、71度以上：灰色で表示します。\n人間が足で無理なく登り降り出来る範囲は白抜きと緑の部分で、それ以上の斜度になると転滑落のリスクが高くなります。また、白抜きや緑のエリアでも崖や滝がある場合もあります。あくまで傾斜量図からの推定角度で色分けしたものです。",

		}
	}
	};

	class Translator {
	constructor(resources) {
		this.resources = resources;
		this.language = this.detectLanguage();
	}

	detectLanguage() {
		const language = navigator.language || navigator.userLanguage;
		return language.startsWith('ja') ? 'ja' : 'en';
	}

	t(key) {
		return this.resources[this.language]?.translation[key] || key;
	}
	}

export { Translator, resources };