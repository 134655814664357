import React, { useState, useEffect } from 'react';
import './css/LayerListPanel.css';
import { Translator, resources } from './Translator';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const DataListPanel = ({ map, fm, uuid,owneruid, myUid, mapManager, photos, markers, files, isOpen, onClose,onDeleteFile,onDeleteMarker,onDeletePhoto}) => {
	const [isMarkerListOpen, setIsMarkerListOpen] = useState(false);
	const [isPhotoListOpen, setIsPhotoListOpen] = useState(false);
	const [isDataListOpen, setIsDataListOpen] = useState(false);

	const translator = new Translator(resources);
	const t = (key) => translator.t(key);

	const toggleMarkerList = () => {
		setIsMarkerListOpen(!isMarkerListOpen);
	};

	const togglePhotoListOpen = () => {
		setIsPhotoListOpen(!isPhotoListOpen);
	};

	const toggleDataList = () => {
		setIsDataListOpen(!isDataListOpen);
	};

	const handleMarkerClick = (coordinates) => {
		map.flyTo({
			center: coordinates,
			essential: true
		});
	};

	const renderFileItem = (file, index) => {
		let content;
		let title;
		switch (file.data.type) {
			case 2:
				title = `${t('Draw')}:${file.data.ownername}` || `${t('Draw')}-${index}`;
				break;
			case 3:
				title = `${t('Shape')}:${file.data.ownername}` || `${t('Shape')}-${index}`;
				break;
			case 4:
				let fileName = file.data.fileName || "";
				title = `${t('File')}${file.data.ownername} : ${fileName}` || `${t('File')}-${index}`;
				break;
			default:
				title = file.data.fileName || `etc-${index}`;
		}
		content = <span>{title}</span>;

		return (
			<div className="file-item">
				{content}
				<button className="dl_button" onClick={() => fileDownload(file)}>{t('download')}</button>
				{(file.data.owneruid === myUid || myUid === owneruid) && (
					<button className="delete_button" onClick={() => fileDelete(file,title)}>{t('delete')}</button>
				)}
			</div>
		);
	};

	const fileDelete = async (file,title) => {
		file.fileTitle = title;
		onDeleteFile(file);
	}

	const zipDownload = async () => {
		console.log('zipDownload:');
		const zip = new JSZip();

		// filesからファイルを作成
		for (const file of files) {
			try {
				let content, fileName;
				if (file.data.owneruid && (file.data.type === 2 || file.data.type === 3)) {
					const geoJSON = mapManager.getGeoJson(file.data.type, file.data.owneruid);
					if (!geoJSON) continue; // GeoJSONが見つからない場合はスキップ
					content = JSON.stringify(geoJSON);
					let type = file.data.type === 2 ? 'drawing' : 'shape';
					fileName = `${type}_${file.data.ownername || file.data.owneruid}.geojson`;
				}
				else if (file.fileData instanceof Promise) {
					content = await file.fileData;
					fileName = file.data.fileName || 'unknown.xml';
				}
				else {
					console.warn('Skipping file due to unknown format:', file);
					continue;
				}
				zip.file(fileName, content);
			} catch (error) {
				console.error('Error processing file:', file, error);
			}
		}

		// markersからGPXファイルを作成
		const markerGpxContents = mapManager.makeMarkerGPX("marker");
		const photoGpxContents = mapManager.makeMarkerGPX("picture");
		const projectName = mapManager.currentProject.title || "project";
		const now = new Date();
		const markerGpxFileName = `${projectName}_markers.gpx`;
		const photoGpxFileName = `${projectName}_pictures.gpx`;
//		const markerGpxFileName = `${projectName}_markers_${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}.gpx`;
//		const photoGpxFileName = `${projectName}_pictures_${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}.gpx`;

		zip.file(markerGpxFileName, markerGpxContents);
		zip.file(photoGpxFileName,photoGpxContents);

		const url = window.location.href;
		const replacedUrl = url.replace('http://localhost:3000/', 'https://collabomap.com/');

		// index.jsonの作成と追加
		const indexData = {
			creater:"CollaboMap",
			zipVersion:"1.0",
			projectName: projectName,
			uuid:uuid,
			owneruid:owneruid,
			url:replacedUrl,
			ownerName: mapManager.currentProject.ownername || "",
			createdAt: now.toISOString(),
		};
		zip.file('index.json', JSON.stringify(indexData, null, 2));


		try {
			// Zipファイルを生成
			const content = await zip.generateAsync({type:"blob"});
			
			// Zipファイルをダウンロード
//			const zipFileName = `${projectName}_files_.zip`;
			const zipFileName = `${projectName}_files_${now.getFullYear().toString().slice(-2)}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}.zip`;
			saveAs(content, zipFileName);

			console.log('Zip file download initiated for:', zipFileName);
		} catch (error) {
			console.error('Error generating zip file:', error);
			// ここでユーザーにエラーメッセージを表示するなどの処理を追加できます
		}
	};

	const markersDownload = () => {
		console.log('markersDownload:');
		const gpxContents = mapManager.makeMarkerGPX("marker");

		try {
			// GPXコンテンツをBlobに変換
			const blob = new Blob([gpxContents], { type: 'application/gpx+xml' });
			
			// ファイル名を設定（現在の日時を含める）
			let projectName = mapManager.currentProject.title;
			if(!projectName) projectName = "";
			const now = new Date();
			const fileName = `${projectName}_markers.gpx`;

			//const fileName = `${projectName}_markers_${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}.gpx`;

			// ダウンロードURLの作成
			const url = URL.createObjectURL(blob);
			
			// ダウンロードリンクの作成と実行
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			
			// クリーンアップ
			URL.revokeObjectURL(url);
			document.body.removeChild(a);

			console.log('GPX file download initiated for:', fileName);
		} catch (error) {
			console.error('Error downloading GPX file:', error);
			// ここでユーザーにエラーメッセージを表示するなどの処理を追加できます
		}
	};
	const fileDownload = async (file) => {
		console.log('Downloading file:', file.docId, file.data);

		let blob, fileName;

		try {
			if (file.fileData instanceof Promise) {
				const content = await file.fileData;
				fileName = file.data.fileName || 'download.xml';
				blob = new Blob([content], { type: 'application/xml' });
			}
			else if (file.data.owneruid && file.data.type === 2 || file.data.type === 3) {
				const geoJSON = mapManager.getGeoJson(file.data.type,file.data.owneruid);
				if(!geoJSON) throw new Error('GeoJson is not found');

				blob = new Blob([JSON.stringify(geoJSON)], { type: 'application/json' });
				if(file.data.fileName) fileName = file.data.fileName;
				else {
					let type = 'drawing';
					if(file.data.type === 3) type = 'shape';
					fileName = `${type}_${file.data.ownername || file.data.owneruid}.geojson`;
				}
			}
			else {
				throw new Error('Invalid file data format');
			}

			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			URL.revokeObjectURL(url);
			document.body.removeChild(a);

			console.log('File download initiated for:', fileName);
		} catch (error) {
			console.error('Error downloading file:', error);
			// ここでユーザーにエラーメッセージを表示するなどの処理を追加できます
		}
	};

	return (
		<div className={`layer-list-panel ${isOpen ? 'open' : ''}`}>
			<h2>{t('Data_list')}</h2>
			<button className="buttonN" onClick={onClose}>{t('Close')}</button>
			<div className="header">
				<button onClick={() => zipDownload()}>{t('Zip download')}</button>
			</div>
			<ul>
				<li>
					<span onClick={toggleMarkerList} style={{ cursor: 'pointer' }}>
						{isMarkerListOpen ? '▼' : '▶'}{t('Markers')}:{markers.length} <button className="dl_button" onClick={markersDownload}>{t('download')}</button>
					</span>
					{isMarkerListOpen && (
						<ul>
							{markers.map((marker, index) => (
								<li key={marker.docId} className='listItem'>
									<span onClick={() => handleMarkerClick([marker.marker.lng, marker.marker.lat])}>
										{`${marker.marker.name} : ${marker.marker.data.ownername}` || `marker-${index}`}
									</span>
									{(marker.marker.data.owneruid === myUid || myUid === owneruid) && (
										<button className="delete_button" onClick={() => onDeleteMarker(marker)}>{t('delete')}</button>
									)}
								</li>
							))}
						</ul>
					)}
				</li>
				<li>
					<span onClick={togglePhotoListOpen} style={{ cursor: 'pointer' }}>
						{isPhotoListOpen ? '▼' : '▶'}{t('Photograph')}:{photos.length}
					</span>
					{isPhotoListOpen && (
						<ul>
							{photos.map((photo, index) => (
								<li key={photo.docId} className='listItem'>
									<div className="photo-item">
									<img 
										src={photo.iconDataUrl} 
										alt={photo.name} 
										className="photo-thumbnail"
										onClick={() => handleMarkerClick([photo.lng, photo.lat])}
									/>
									<span onClick={() => handleMarkerClick([photo.lng, photo.lat])}>
										{`${photo.name} : ${photo.ownername}` || `marker-${index}`}
									</span>
									{(photo.owneruid === myUid || myUid === owneruid) && (
										<button className="delete_button" onClick={() => onDeletePhoto(photo)}>{t('delete')}</button>
									)}
									</div>
								</li>
							))}
						</ul>
					)}
				</li>
				<li>
					<span onClick={toggleDataList} style={{ cursor: 'pointer' }}>
						{isDataListOpen ? '▼' : '▶'}{t('Shape')}:{files.length}
					</span>
					{isDataListOpen && (
						<ul>
							{files.map((file, index) => (
								<li key={file.docId} className='listItem'>
									{renderFileItem(file, index)}
								</li>
							))}
						</ul>
					)}
				</li>
				<li>
					{t('total')}:{markers.length + photos.length + files.length}
				</li>
			</ul>
		</div>
	);

};


export default DataListPanel;
