// FileTypeChecker.js

export class FileTypeChecker {
	static GPX_TYPE_UNKNOWN = 0;
	static GEOJSON_TYPE = 1;
	static KMZ_TYPE = 2;
	static ZIP_TYPE = 3;
	static KML_TYPE_MARKER = 4;
	static KML_TYPE_TRACK = 5;
	static GPX_TYPE_TRACK = 6;
	static GPX_TYPE_ROUTE = 7;
	static GPX_TYPE_ROUTE_L = 8;
	static GPX_TYPE_MARKER = 9;
	static GPX_TYPE_PICTURE_GEOGRAPHICA = 10;
	static GPX_TYPE_PICTURE_COLLABOMAP = 11;
	static OUT_OF_MEMORY = -1;
	static GPX_TYPE_ROUTE_MAXWP = 1000;

	static async checkFileType(file) {
		let ret = FileTypeChecker.GPX_TYPE_UNKNOWN;  // thisをクラス名に変更

		const fileName = file.name.toLowerCase();

		if (fileName.endsWith('geojson')) {
			return FileTypeChecker.GEOJSON_TYPE;  // thisをクラス名に変更
		}
		else if (fileName.endsWith('kmz')) {
			return FileTypeChecker.KMZ_TYPE;  // thisをクラス名に変更
		}
		else if (fileName.endsWith('zip')) {
			return FileTypeChecker.ZIP_TYPE;  // thisをクラス名に変更
		}

		try {
			const content = await FileTypeChecker.readFileContent(file);

			if (content.startsWith('{"type":"FeatureCollection"')) {
				return FileTypeChecker.GEOJSON_TYPE;
			}

			if (fileName.endsWith('kml')) {
				const lowerContent = content.toLowerCase();
				if (lowerContent.includes('<point>') && lowerContent.includes('<description>')) {
					return FileTypeChecker.KML_TYPE_MARKER;
				}
				return FileTypeChecker.KML_TYPE_TRACK;
			}
			else {
				if (content.includes('<trkseg>')) {
					ret = FileTypeChecker.GPX_TYPE_TRACK;
				} else {
					if (content.includes('</rtept>')) {
						let wpcount = 1;
						let pos = 0;
						ret = FileTypeChecker.GPX_TYPE_ROUTE;

						while ((pos = content.indexOf('</rtept>', pos)) !== -1) {
							wpcount++;
							pos++;
							if (wpcount > FileTypeChecker.GPX_TYPE_ROUTE_MAXWP) {
								ret = FileTypeChecker.GPX_TYPE_ROUTE_L;
								break;
							}
						}
						console.log('</rtept> count:', wpcount);
					} else if (content.includes('</wpt>')) {
						if (await FileTypeChecker.isPicGpxFile(content)) {
							if(content.includes('creator="collabomap')) {
								ret = FileTypeChecker.GPX_TYPE_PICTURE_COLLABOMAP;
							}
							else if(content.includes('creator="Geographica')) {
								ret = FileTypeChecker.GPX_TYPE_PICTURE_GEOGRAPHICA;
							}
						} else {
							ret = FileTypeChecker.GPX_TYPE_MARKER;
						}
					}
				}
			}
		} catch (error) {
			console.error('Error checking file type:', error);
			if (error.name === 'OutOfMemoryError') {
				return FileTypeChecker.OUT_OF_MEMORY;  // thisをクラス名に変更
			}
		}

		console.log('Detected file type:', ret);
		return ret;
	}

	static async readFileContent(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e) => {
				resolve(e.target.result);
			};
			reader.onerror = (e) => {
				reject(e);
			};
			reader.readAsText(file);
		});
	}

	static async isPicGpxFile(content) {
		return content.includes('<photo>') || 
				content.includes('xmlns:type="picture"') || 
				content.includes('photo=') || 
				content.includes('image=');
	}
}