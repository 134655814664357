import React from 'react';

const ConfirmDialog = ({ message, data, okTitle, cancelTitle, onConfirm, onCancel }) => {
	const styles = {
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: '10000',
			color: 'white',
		},
		dialog: {
			backgroundColor: '#30495a',
			padding: '20px',
			maxWidth: '480px',
			borderRadius: '12px',
			textAlign: 'center',
		},
		button: {
			margin: '10px',
			padding: '10px 20px',
			borderRadius: '5px',
			border: 'none',
			cursor: 'pointer',
		},
		buttonOK: {
			backgroundColor: '#f93',
			color: 'white',
		},
		buttonCancel: {
			backgroundColor: '#39c',
			color: 'white',
		},
	};

	const handleConfirm = () => {
		if (onConfirm && typeof onConfirm === 'function') {
			onConfirm(data);
		}
	};

	const handleCancel = () => {
		if (onCancel && typeof onCancel === 'function') {
			onCancel(data);
		}
	};

	return (
		<div style={styles.overlay}>
			<div style={styles.dialog}>
				<p>{message}</p>
				{cancelTitle && (
					<button
						style={{ ...styles.button, ...styles.buttonCancel }}
						onClick={handleCancel}
					>
						{cancelTitle}
					</button>
				)}
				{okTitle && (
					<button
						style={{ ...styles.button, ...styles.buttonOK }}
						onClick={handleConfirm}
					>
						{okTitle}
					</button>
				)}
			</div>
		</div>
	);
};

export default ConfirmDialog;