
export class CommonUtils {
	static colors = [
		0xdd5144, 0xf09000, 0xa06b04, 0xb0c000, 0x498702, 0x06cc00, 0x04933d, 0x00d080, 0x028d98, 0x00c1d0,
		0x3f76da, 0xad9fff, 0x985bdf, 0xff71ff, 0xda4ca2, 0xf070c0,
	];


//  { type:'', name: '', url: '', cp:'',mz:,bp:0,op:100,cc:0 },
	static overlays = {
		'null':{ type:'', name: '', url: '', cp:'',ts:256, minz:0, mz:0,bp:0,op:0,cc:0 },
		'relief':{ type:'jp', name: '色別標高図', url: 'https://cyberjapandata.gsi.go.jp/xyz/relief/{z}/{x}/{y}.png', cp:'国土地理院',ts:256, minz:5, mz:15,bp:0,op:40,cc:0,proxy:false },
		'hillshademap':{ type:'jp', name: '陰影起伏図', url: 'https://cyberjapandata.gsi.go.jp/xyz/hillshademap/{z}/{x}/{y}.png', cp:'国土地理院',ts:256, minz:3 ,mz:16,bp:0,op:40,cc:0,proxy:false },
		'slopemap':{ type:'jp', name: '傾斜量図', url: 'https://cyberjapandata.gsi.go.jp/xyz/slopemap/{z}/{x}/{y}.png', cp:'国土地理院',ts:256, minz:3 ,mz:15,bp:0,op:40,cc:0,proxy:false },
		'slopemap_b':{ type:'jp', name: '色別傾斜区分図', url: 'https://cyberjapandata.gsi.go.jp/xyz/slopemap/{z}/{x}/{y}.png', cp:'国土地理院:傾斜量図の色をコラボマップで変更',ts:256, minz:3 ,mz:15,bp:0,op:35,cc:0,proxy:false },
		'slopezone1map':{ type:'jp', name: '雪崩傾斜', url: 'https://cyberjapandata.gsi.go.jp/xyz/slopezone1map/{z}/{x}/{y}.png', cp:'国土地理院',ts:256, minz:3 ,mz:15,bp:0,op:40,cc:0,proxy:false },
		'slopezone1map_b':{ type:'jp', name: '雪崩傾斜タイプB', url: 'https://cyberjapandata.gsi.go.jp/xyz/slopezone1map/{z}/{x}/{y}.png', cp:'国土地理院:雪崩傾斜と傾斜量図をコラボマップで合成して変色',ts:256, minz:3 ,mz:15,bp:0,op:40,cc:0,proxy:false },
		'afm':{ type:'jp', name: '活断層図', url: 'https://cyberjapandata.gsi.go.jp/xyz/afm/{z}/{x}/{y}.png', cp:'国土地理院',ts:256, minz:11 ,mz:16,bp:0,op:40,cc:0,proxy:false },
		'lcmfc2':{ type:'jp', name: '治水地形分類図', url: 'https://cyberjapandata.gsi.go.jp/xyz/lcmfc2/{z}/{x}/{y}.png', cp:'国土地理院',ts:256, minz:11 ,mz:16,bp:0,op:40,cc:0,proxy:false },
		'swale':{ type:'jp', name: '明治期の低湿地', url: 'https://cyberjapandata.gsi.go.jp/xyz/swale/{z}/{x}/{y}.png', cp:'国土地理院',ts:256, minz:10 ,mz:16,bp:0,op:40,cc:0,proxy:false },
		'vlcd':{ type:'jp', name: '火山土地条件図', url: 'https://cyberjapandata.gsi.go.jp/xyz/vlcd/{z}/{x}/{y}.png', cp:'国土地理院',ts:256, minz:10 ,mz:16,bp:0,op:50,cc:0,proxy:false },
		'seamless':{ type:'jp', name: 'シームレス地質図', url: 'https://gbank.gsj.jp/seamless/v2/api/1.2/tiles/{z}/{y}/{x}.png', cp:'産業技術総合研究所地質調査総合センター',ts:256, minz:3 ,mz:13,bp:0,op:40,cc:0,proxy:false },
		'landslide':{ type:'jp', name: '地すべり地形分布図日本全国版', url: 'https://www.j-shis.bosai.go.jp/map/xyz/landslide/{z}/{x}/{y}.png', cp:'防災科学技術研究所地震ハザードステーション',ts:256, minz:5 ,mz:15,bp:0,op:40,cc:0,proxy:false },
		'pale':{ type:'jp', name: '淡色地図', url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png', cp:'国土地理院',ts:128, minz:5 ,mz:17,bp:0,op:40,cc:0,proxy:false },
		'osm':{ type:'osm', name: 'OpenStreetMap', url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png', cp:'OpenStreetMap', ts:256, minz:1, mz:18,bp:0,op:40,cc:0,proxy:false },
	};

	static maps = {
		'pale':{ type:'jp', name: '淡色地図', url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png', cp:'国土地理院',ts:128, minz:5 ,mz:17,bp:0,op:100,cc:0,proxy:false },
		'std':{ type:'jp', name: '標準地図', url: 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png', cp:'国土地理院',ts:128, minz:5 ,mz:17,bp:0,op:100,cc:0,proxy:false },
		'seamlessphoto':{ type:'jp', name: '全国最新写真', url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg', cp:'国土地理院', ts:256, minz:2 ,mz:18,bp:0,op:100,cc:0,proxy:false },
		'vbm':{ type:'jp', name: '火山基本図', url: 'https://cyberjapandata.gsi.go.jp/xyz/vbm/{z}/{x}/{y}.png', cp:'国土地理院', ts:256, minz:11, mz:18,bp:0,op:100,cc:0,proxy:false },
		'vbmd_bm':{ type:'jp', name: '火山基本図データ', url: 'https://cyberjapandata.gsi.go.jp/xyz/vbmd_bm/{z}/{x}/{y}.png', cp:'国土地理院', ts:256, minz:11, mz:18,bp:0,op:100,cc:0,proxy:false },
		'lake1':{ type:'jp', name: '湖沼図', url: 'https://cyberjapandata.gsi.go.jp/xyz/lake1/{z}/{x}/{y}.png', cp:'国土地理院', ts:256, minz:11, mz:17,bp:0,op:100,cc:0,proxy:false },
		'lakedata':{ type:'jp', name: '湖沼データ', url: 'https://cyberjapandata.gsi.go.jp/xyz/lakedata/{z}/{x}/{y}.png', cp:'国土地理院', ts:256, minz:11, mz:18,bp:0,op:100,cc:0,proxy:false },
		'osm':{ type:'osm', name: 'OpenStreetMap', url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png', cp:'OpenStreetMap', ts:256, minz:1, mz:17,bp:0,op:100,cc:0,proxy:false },
		'otm':{ type:'osm', name: 'OpenTopoMap', url: 'https://b.tile.opentopomap.org/{z}/{x}/{y}.png', cp:'OpenStreetMap', ts:256, minz:1, mz:17,bp:0,op:100,cc:0,proxy:false },
	};

	delayPromise(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	delay(ms, callback) {
		setTimeout(callback, ms);
	}

	static formatDate(date) {
		return date.toISOString().split('T')[0];
	}

	static isElementInViewport(el) {
		var rect = el.getBoundingClientRect();
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}

	static formatDateWithIsSec(date,isSec = true) {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');
	
		if(isSec)	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

		return `${year}-${month}-${day} ${hours}:${minutes}`;
	}

	static formatDateT(now) {
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return CommonUtils.formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
	}


	static formatDateHHMM(date) {
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');

		return `${hours}:${minutes}`;
	}
	

	static capitalizeString(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	static generateRandomId() {
		return Math.random().toString(36).substr(2, 9);
	}

	static isValidEmail(email) {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	static generateUUID() {
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
			(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
		);
	}
	static isiPad() {
		const ua = navigator.userAgent;
		return /iPad|Macintosh/i.test(ua) && 'ontouchend' in document;
	}

	static isIOS() {
		const userAgent = CommonUtils.getPlatform().toLowerCase();
		return /iphone|ipad|ipod/.test(userAgent);
	}

	static getPlatform() {
		let platform = 'Unknown Platform';
		if (!navigator || !navigator.userAgent) {
			return platform;
		}
		const userAgent = navigator.userAgent;
		if (userAgent.includes('Android')) {
			platform = 'Android';
		} else if (userAgent.includes('iPhone')) {
			platform = 'iPhone';
		} else if (userAgent.includes('iPad')) {
			platform = 'iPad';
		} else if (userAgent.includes('Win')) {
			platform = 'Windows';
		} else if (userAgent.includes('Mac')) {
			platform = 'Macintosh';
		} else if (userAgent.includes('Linux')) {
			platform = 'Linux';
		}

		return platform;
	}

	static formatString(template, ...values) {
		return template.replace(/{(\d+)}/g, (match, number) => { 
			return typeof values[number - 1] !== 'undefined'
			? values[number - 1]
			: match;
		});
	}

	static convertToHashtags(text) {
		console.log('convertToHashtags:' + text);
		if(!text || text === undefined) return "";
		
		if (typeof text !== 'string') {
			console.warn('Input is not a string, attempting to convert');
			text = String(text);
		}
		const words = text.split(/[,\s]+/).filter(word => word.length > 0);
		const hashtags = words.map(word => `#${word}`);
		return hashtags.join(' ');
	}

	static async getAltitudeByGoogle(lat, lng) {
		console.log('getAltitudeByGoogle:', lat + ':' + lng);
		return null;
/*		const url = `https://asia-northeast1-collabomap-1bcb9.cloudfunctions.net/getAltitude?lat=${lat}&lng=${lng}`;

		console.log('getAltitudeByGoogle:', lat + ':' + lng);
		try {
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			const data = await response.json();
			if (data.status !== 'OK') {
				throw new Error(`Error fetching data: ${data.status}`);
			}
			const altitude = data.results[0].elevation;
			if (altitude && typeof altitude !== 'undefined') return data.results[0].elevation.toFixed(0);
			else return null;
		} catch (error) {
			console.error('Error fetching altitude data:', error);
			return null;
		}*/
	}

	static async getAltitudeByKokudo(lat, lng) {
		console.log('getAltitudeByKokudo:', lat + ':' + lng);
		const url = `https://cyberjapandata2.gsi.go.jp/general/dem/scripts/getelevation.php?lon=${lng}&lat=${lat}&outtype=JSON`;
		try {
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			const data = await response.json();
			if (data.elevation !== undefined) {
				return data.elevation.toFixed(0);
			} else {
				throw new Error('No elevation data found');
			}
		} catch (error) {
			console.error('Error fetching altitude data:', error);
			return CommonUtils.getAltitudeByGoogle(lat, lng);
		}
	}

	//ユーザーアイコン関連

	static makeCircleIcon(imgUrl,name,width,height,lineWidth,color,callback) {
		if(imgUrl) {
			var overlayImage = new Image();
			overlayImage.crossOrigin = "Anonymous";
			overlayImage.onload = function() {
				var canvas = CommonUtils.makeCircleIconBase(overlayImage,name,width,height,lineWidth,color);
				callback(canvas.toDataURL('image/png'));
			};
			overlayImage.src = imgUrl;
		}
		else {
			var canvas = CommonUtils.makeCircleIconBase(null,name,width,height,lineWidth,color);
			callback(canvas.toDataURL('image/png'));
		}
	}
	static makeCircleIconBase(overlayImage,name,width,height,lineWidth,color){
		var canvas = document.createElement('canvas');
		var context = canvas.getContext('2d');

		var radius = Math.min(width, height) / 2;
		canvas.width = width;
		canvas.height = height;

		context.beginPath();
		context.arc(width / 2, height / 2, radius - lineWidth, 0, Math.PI * 2);
		context.strokeStyle = color;
		context.lineWidth = lineWidth;
		context.stroke();

		context.beginPath();
		context.arc(width / 2, height / 2, radius - lineWidth * 1.5, 0, Math.PI * 2);
		context.fillStyle = '#fff';
		context.fill();

		//name = "aa";

		if(overlayImage) {
			const offset = lineWidth * 2;
			context.beginPath();
			context.arc(width / 2, height / 2, radius - lineWidth * 2.5, 0, Math.PI * 2, true);
			context.clip();
			context.drawImage(overlayImage, offset, offset, width - offset,height - offset);
		}
		else if(name){
			let firstChar = name.charAt(0);
			context.fillStyle = color;
			if(name.length > 1 && CommonUtils.areFirstTwoCharsAscii(name)) {
				const offset = lineWidth * 5;
				firstChar = name.substring(0, 2);
				context.font = `bold ${height * 0.7}px Arial`;
				context.fillText(firstChar, offset, height -lineWidth * 4- offset,width - offset);
			}
			else {
				const offset = lineWidth * 6;
				context.font = `bold ${height * 0.65}px Arial`;
				context.fillText(firstChar, offset, height -lineWidth * 3- offset,width - offset);
			}
		}
		return canvas;
	}
	static areFirstTwoCharsAscii(str) {
		return /^[ -~]{2}/.test(str);
	}
	static numberToHexColor(number) {
		if(!number) number = 0;
		return '#' + number.toString(16).padStart(6, '0');
	}

	static reduceArrayToFraction(array, n) {
		if (n <= 0) {
			throw new Error("N must be a positive integer");
		}
		
		const result = [];
		const step = Math.max(1, Math.floor(array.length / n));
		
		for (let i = 0; i < array.length; i += step) {
			result.push(array[i]);
		}
		
		return result;
	}

	/**
	* 座標配列を曲率に基づいて動的に間引き、スムージングを行う
	* @param {Array<{lat: number, lng: number}>} array - 座標配列
	* @param {Object} options - オプション
	* @param {number} options.maxReduction - 最大間引き率 (例: 10なら最大1/10まで間引く)
	* @param {number} options.minReduction - 最小間引き率 (例: 2なら最小1/2まで間引く)
	* @param {number} options.curvatureThreshold - 曲率の閾値 (小さいほど敏感に反応)
	* @param {number} options.smoothingFactor - スムージングの強さ (0-1)
	* @returns {Array<{lat: number, lng: number}>} 処理後の座標配列
	*/
	static dynamicPointReduction(array, options = {}) {
		const {
			maxReduction = 5,      // 最大間引き率を5に減らす
			minReduction = 1,      // 最小間引き率を1に変更
			curvatureThreshold = 0.05,  // より敏感に
			smoothingFactor = 0.2   // スムージングを少し控えめに
		} = options;

		if (array.length < 3) return array;

		// 曲率を計算する関数
		function calculateCurvature(p1, p2, p3) {
			// ベクトルの変化を計算
			const v1 = {
				lat: p2[1] - p1[1],
				lng: p2[0] - p1[0]
			};
			const v2 = {
				lat: p3[1] - p2[1],
				lng: p3[0] - p2[0]
			};

			// ベクトルの角度変化を計算
			const dot = v1.lat * v2.lat + v1.lng * v2.lng;
			const mag1 = Math.sqrt(v1.lat * v1.lat + v1.lng * v1.lng);
			const mag2 = Math.sqrt(v2.lat * v2.lat + v2.lng * v2.lng);
			
			if (mag1 === 0 || mag2 === 0) return 1; // 変更: 0の代わりに1を返す
			
			const cosAngle = dot / (mag1 * mag2);
			return Math.abs(1 - Math.min(1, Math.max(-1, cosAngle)));
		}

		// 曲率に基づいて間引き率を決定する
		function getReductionRate(curvature) {
			// 曲率が閾値を超えた場合は最小間引き率を使用
			if (curvature > curvatureThreshold) {
				return minReduction;
			}
			
			// 曲率が小さい場合は比例的に間引き率を増加
			const rate = Math.floor(
				minReduction + (maxReduction - minReduction) * 
				(1 - curvature / curvatureThreshold)
			);
			
			return Math.max(minReduction, Math.min(maxReduction, rate));
		}

		// スムージング処理
		function smoothPoints(points) {
			const smoothed = [];
			for (let i = 0; i < points.length; i++) {
				if (i === 0 || i === points.length - 1) {
					smoothed.push({ ...points[i] });
					continue;
				}

				const prev = points[i - 1];
				const curr = points[i];
				const next = points[i + 1];

				// [経度, 緯度] の順で計算
				smoothed.push([
					curr[0] * (1 - smoothingFactor) + 
					(prev[0] + next[0]) * smoothingFactor * 0.5,
					curr[1] * (1 - smoothingFactor) + 
					(prev[1] + next[1]) * smoothingFactor * 0.5
				]);
			}
			return smoothed;
		}

		// メイン処理
		const result = [array[0]]; // 最初の点を追加
		let i = 0;
		
		while (i < array.length - 2) {
			const curvature = calculateCurvature(
				array[i],
				array[i + 1],
				array[i + 2]
			);
			
			const reduction = getReductionRate(curvature);
			
			// デバッグログ
			console.log(`Point ${i}: Curvature = ${curvature.toFixed(4)}, Reduction = ${reduction}`);
			
			// 次の点を追加
			i += reduction;
			if (i < array.length) {
				result.push(array[i]);
			}
		}
		
		// 最後の点を必ず含める
		if (result[result.length - 1] !== array[array.length - 1]) {
			result.push(array[array.length - 1]);
		}

		// スムージングを適用
		return smoothPoints(result);
	}

	static escapeXml(unsafe) {
		return unsafe.replace(/[<>&'"]/g, function (c) {
			switch (c) {
				case '<': return '&lt;';
				case '>': return '&gt;';
				case '&': return '&amp;';
				case "'": return '&apos;';
				case '"': return '&quot;';
				default: return c;
			}
		});
	}

	static roundCoordinate(coord) {
		return [
			Number(coord[0].toFixed(7)),
			Number(coord[1].toFixed(7))
		];
	}

	static removeDuplicateConsecutiveCoordinates(coordinates) {
		return coordinates.map(this.roundCoordinate).filter((coord, index, array) => {
			if (index === 0) return true; // 最初の要素は常に保持
			
			// 現在の座標と前の座標を比較
			const prev = array[index - 1];
			return !(coord[0] === prev[0] && coord[1] === prev[1]);
		});
	}

	// 地球の半径（メートル）
	static EARTH_RADIUS = 6371000;
	// 2点間の距離を計算する関数（ハバーサイン公式）
	static calculateDistance(point1, point2) {
		const [lon1, lat1] = point1;
		const [lon2, lat2] = point2;

		const dLat = this.toRadians(lat2 - lat1);
		const dLon = this.toRadians(lon2 - lon1);

		const a = 
		Math.sin(dLat/2) * Math.sin(dLat/2) +
		Math.cos(this.toRadians(lat1)) * Math.cos(this.toRadians(lat2)) * 
		Math.sin(dLon/2) * Math.sin(dLon/2);

		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

		return this.EARTH_RADIUS * c;
	}

	static calculateDistanceWithElevationArray(points) {
		// 点が2つ未満の場合は0を返す
		if (points.length < 2) {
			return 0;
		}

		let totalDistance = 0;

		// 隣接する点との距離を計算して合計
		for (let i = 0; i < points.length - 1; i++) {
			const point1 = points[i];
			const point2 = points[i + 1];
			
			const distance = this.calculateDistanceWithElevation(point1, point2);
			totalDistance += distance;
		}

		return totalDistance;
	}

	static calculateDiffElevationArray(points) {
		// 点が2つ未満の場合は初期値を返す
		if (points.length < 2) {
			return {
				ascent: 0,  // 上り合計
				descent: 0  // 下り合計
			};
		}

		let ascent = 0;  // 登り累計
		let descent = 0; // 下り累計

		// 隣接する点との標高差を計算
		for (let i = 0; i < points.length - 1; i++) {
			const point1 = points[i];
			const point2 = points[i + 1];
			
			const elevation1 = point1[2]; // 1つ目の点の標高
			const elevation2 = point2[2]; // 2つ目の点の標高
			
			const elevationDiff = elevation2 - elevation1;

			// 標高差が正（上り）の場合
			if (elevationDiff > 0) {
				ascent += elevationDiff;
			}
			// 標高差が負（下り）の場合
			else if (elevationDiff < 0) {
				descent += Math.abs(elevationDiff);
			}
		}

		return {
			ascent,  // 上り合計
			descent  // 下り合計
		};
	}

	static calculateDistanceWithElevation(point1, point2) {
		const [lon1, lat1, elevation1] = point1;
		const [lon2, lat2, elevation2] = point2;

		const dLat = this.toRadians(lat2 - lat1);
		const dLon = this.toRadians(lon2 - lon1);

		const a = 
			Math.sin(dLat/2) * Math.sin(dLat/2) +
			Math.cos(this.toRadians(lat1)) * Math.cos(this.toRadians(lat2)) * 
			Math.sin(dLon/2) * Math.sin(dLon/2);

		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

		// 地表面上の距離（メートル）
		const surfaceDistance = this.EARTH_RADIUS * c;

		// 標高差（メートル）
		const elevationDifference = Math.abs(elevation2 - elevation1);

		// ピタゴラスの定理を使用して斜辺の距離を計算
		const distanceWithElevation = Math.sqrt(
			Math.pow(surfaceDistance, 2) + Math.pow(elevationDifference, 2)
		);

		return distanceWithElevation;
	}
	
	// ラジアンに変換する関数
	static toRadians(degrees) {
		return degrees * Math.PI / 180;
	}


	static calculateTotalDistance(coordinates) {
		let totalDistance = 0;

		// 各座標ペア間の距離を計算し、合計に加算
		for (let i = 1; i < coordinates.length; i++) {
			totalDistance += this.calculateDistance(coordinates[i-1], coordinates[i]);
		}

		return totalDistance;
	}

	//ポリゴンの面積を計算（水平面積）
	static calculatePolygonArea(coordinates) {
		if (coordinates.length < 3) {
			throw new Error("A polygon must have at least 3 coordinates");
		}

		let total = 0;
		const R = this.EARTH_RADIUS;

		for (let i = 0; i < coordinates.length; i++) {
			const [lon1, lat1] = coordinates[i];
			const [lon2, lat2] = coordinates[(i + 1) % coordinates.length];

			const φ1 = this.toRadians(lat1);
			const φ2 = this.toRadians(lat2);
			const Δλ = this.toRadians(lon2 - lon1);

			const areaPart = Math.sin(φ1) + Math.sin(φ2);
			total += Δλ * areaPart;
		}

		// 面積を計算（結果は立方メートル）
		const area = Math.abs(total * R * R / 2);

		return area; // 平方メートル
	}

	//ポリゴンの面積を計算（傾斜面積）
	static calculatePolygonTiltArea(coordinates) {
		if (coordinates.length < 3) {
			throw new Error("A polygon must have at least 3 coordinates");
		}

		let total = 0;
		const R = this.EARTH_RADIUS;

		for (let i = 0; i < coordinates.length; i++) {
			const [lon1, lat1, alt1] = coordinates[i];
			const [lon2, lat2, alt2] = coordinates[(i + 1) % coordinates.length];

			// 地表面での距離計算
			const φ1 = this.toRadians(lat1);
			const φ2 = this.toRadians(lat2);
			const λ1 = this.toRadians(lon1);
			const λ2 = this.toRadians(lon2);

			// 地表面での距離を計算（ヒュベニの公式）
			const latMean = (φ1 + φ2) / 2;
			const reduced_lat = Math.cos(latMean);
			const dx = R * (λ2 - λ1) * reduced_lat;
			const dy = R * (φ2 - φ1);
			const surfaceDistance = Math.sqrt(dx * dx + dy * dy);

			// 標高差を考慮した実際の距離を計算
			const heightDiff = alt2 - alt1;
			const actualDistance = Math.sqrt(surfaceDistance * surfaceDistance + heightDiff * heightDiff);

			// 隣接する3点での面積計算のための準備
			const [lon3, lat3, alt3] = coordinates[(i + 2) % coordinates.length];
			const φ3 = this.toRadians(lat3);
			const λ3 = this.toRadians(lon3);

			// 3点目までの距離を計算
			const dx2 = R * (λ3 - λ2) * Math.cos((φ2 + φ3) / 2);
			const dy2 = R * (φ3 - φ2);
			const surfaceDistance2 = Math.sqrt(dx2 * dx2 + dy2 * dy2);
			const heightDiff2 = alt3 - alt2;
			const actualDistance2 = Math.sqrt(surfaceDistance2 * surfaceDistance2 + heightDiff2 * heightDiff2);

			// 3点間の実距離から面積を計算（ヘロンの公式）
			const dx3 = R * (λ3 - λ1) * Math.cos((φ1 + φ3) / 2);
			const dy3 = R * (φ3 - φ1);
			const surfaceDistance3 = Math.sqrt(dx3 * dx3 + dy3 * dy3);
			const heightDiff3 = alt3 - alt1;
			const actualDistance3 = Math.sqrt(surfaceDistance3 * surfaceDistance3 + heightDiff3 * heightDiff3);

			const s = (actualDistance + actualDistance2 + actualDistance3) / 2;
			const triangleArea = Math.sqrt(s * (s - actualDistance) * (s - actualDistance2) * (s - actualDistance3));

			total += triangleArea;
		}

		return Math.abs(total); // 平方メートル
	}

	static calculateCentroid(coordinates) {
		if (coordinates.length === 0) {
			throw new Error("Coordinates array must not be empty");
		}

		let sumLat = 0;
		let sumLon = 0;

		coordinates.forEach(([lon, lat]) => {
			sumLat += lat;
			sumLon += lon;
		});

		const centroidLat = sumLat / coordinates.length;
		const centroidLon = sumLon / coordinates.length;

		return [centroidLon, centroidLat];
	}

	static calculateCircleArea(radius) {
	    const area = Math.PI * Math.pow(radius, 2);
	    return Number(area.toExponential(2)); // 指数表記で2桁の精度
	}


	// 定数の定義
	static DIST_COEFFICIENT = 1.3;
	static SPEED_COEFFICIENT_UP = 420;
	static SPEED_COEFFICIENT_DOWN = 840;
	static SPEED_COEFFICIENT_DOWNB = 550;
	static HIGHLAND_FACTOR = 2500;
	static HIGHLAND_FACTORB = 2900;

	// 列挙型の代わりにオブジェクトを使用
	static SETTING_ROUTE_REST_TYPE = {
		SHORT: 1,
		NORMAL: 2,
		LONG: 3
	};

	static calcTimeArray(points,walkSpeed) {
		// 点が2つ未満の場合は0を返す
		if (points.length < 2) {
			return 0;
		}
		let totalTimeMin = 0;

		// 隣接する点との距離を計算して合計
		for (let i = 0; i < points.length - 1; i++) {
			const point1 = points[i];
			const point2 = points[i + 1];
			
			const distance = this.calculateDistanceWithElevation(point1, point2);
			const timeMin = this.calcTime(distance, point1[2], point2[2], walkSpeed);
			totalTimeMin += timeMin;
		}

		return totalTimeMin;
	}	


	//歩行時間を計算　walkSpeedの標準は70 restType:1
	static calcTime(distance, altitudeFrom, altitudeTo, walkSpeed = 70, restType = 2, isTrack = false/*トラックログでない場合は距離が不正確なので直線距離を1.3倍する*/) {
		let ret;

		// 距離補正
		if (!isTrack) distance = distance * this.DIST_COEFFICIENT;

		const altitudeDef = altitudeTo - altitudeFrom;
		const altitudeM = (altitudeTo + altitudeFrom) / 2;

		// 歩行速度補正
		const slopePercentage = Math.abs(altitudeDef) / distance * 100; // 傾き
		if (slopePercentage > 1.0) {
			let slopeFactor;
			// 登りの場合
			if (altitudeDef > 0) {
				slopeFactor = 1 + slopePercentage * slopePercentage / this.SPEED_COEFFICIENT_UP;
			}
			// 下りの場合
			else {
				if (altitudeM > this.HIGHLAND_FACTORB) {
					slopeFactor = 1 + slopePercentage * slopePercentage / this.SPEED_COEFFICIENT_DOWNB;
				} else {
					slopeFactor = 1 + slopePercentage * slopePercentage / this.SPEED_COEFFICIENT_DOWN;
				}
			}
			walkSpeed = walkSpeed / slopeFactor;
			if (walkSpeed <= 1.0) walkSpeed = 1;
		}
		ret = distance / walkSpeed;

		// 高地補正
		if (altitudeM > this.HIGHLAND_FACTOR) {
			ret = ret * (altitudeM / this.HIGHLAND_FACTOR);
		}

		// 休憩補正
		if (restType === this.SETTING_ROUTE_REST_TYPE.SHORT) {
			ret = ret * 1.09;
		} else if (restType === this.SETTING_ROUTE_REST_TYPE.NORMAL) {
			ret = ret * 1.16;
		} else if (restType === this.SETTING_ROUTE_REST_TYPE.LONG) {
			ret = ret * 1.25;
		}

		return ret;
	}

	static cleanupDuplicateLineStrings(geojson) {
		// 座標が実質的に同じかどうかをチェックする関数
		const isSameCoordinate = (coord1, coord2, epsilon = 0.0000001) => {
			return Math.abs(coord1[0] - coord2[0]) < epsilon && 
				Math.abs(coord1[1] - coord2[1]) < epsilon;
		};

		// フィーチャーをフィルタリング
		if (geojson.type === 'FeatureCollection') {
			geojson.features = geojson.features.filter(feature => {
				// LineString以外のフィーチャーはそのまま保持
				if (feature.geometry.type !== 'LineString') {
					return true;
				}

				const coords = feature.geometry.coordinates;
				
				// 座標が2点でない場合はそのまま保持
				if (coords.length !== 2) {
					return true;
				}

				// 2点の座標が実質的に同じ場合は除去
				return !isSameCoordinate(coords[0], coords[1]);
			});
		}

		return geojson;
	}

	// 日付の表示用フォーマット関数
	static formatDateTime_FirestoreOrDateOrString = (dateTime) => {
		if (!dateTime) dateTime = new Date();
		
		try {
			// Firestoreのタイムスタンプオブジェクトの場合
			if (dateTime.seconds) {
				const date = new Date(dateTime.seconds * 1000);
				return date.toISOString();
			}
			// Date オブジェクトの場合
			if (dateTime instanceof Date) {
				return dateTime.toISOString();
			}
			// 文字列の場合、Date型に変換してISOStringに
			if (typeof dateTime === 'string') {
				const date = new Date(dateTime);
				if (!isNaN(date.getTime())) {
					return date.toISOString();
				}
				return dateTime; // パースできない文字列の場合はそのまま返す
			}
			
			return '';
		} catch (error) {
			console.error('Error formatting date:', error);
			return '';
		}
	};
	static parseISODateString = (dateString) => {
		if (!dateString) return null;
		
		try {
			// ISO形式の文字列をDateオブジェクトに変換
			const date = new Date(dateString);
			
			// 無効な日付の場合はnullを返す
			if (isNaN(date.getTime())) {
				return null;
			}
			
			return date;
		} catch (error) {
			console.error('Error parsing date string:', error);
			return null;
		}
	};

	static invertRgb(rgb) {
		// 16進数の文字列から数値に変換
		const numColor = parseInt(rgb.replace('#', ''), 16);
		
		// 各色成分を抽出して反転
		const red = 255 - ((numColor >> 16) & 0xFF);
		const green = 255 - ((numColor >> 8) & 0xFF);
		const blue = 255 - (numColor & 0xFF);
		
		// RGBを結合して16進数文字列に変換
		return '#' + ((red << 16) | (green << 8) | blue).toString(16).padStart(6, '0');
	}

}
