import React, { useState, useEffect } from 'react';
import './css/popup.css';
import { Translator, resources } from './Translator';
import { CommonUtils } from './CommonUtils';

const translator = new Translator(resources);

const AddProjectPopup = ({ fm, onClose, myName, myColor ,userSettings }) => {
	const t = (key) => translator.t(key);
	const [title, setTitle] = useState('');
	const [memo, setMemo] = useState('');
	const [tags, setTags] = useState('');
	const [scope, setScope] = useState('1');
	const [genre, setGenre] = useState('-1');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSearchable, setIsSearchable] = useState(false);
	const [isCopyable, setIsCopyable] = useState(true);
	const [error, setError] = useState('');
	const [overlay, setOverlay] = useState('slopemap');
	const [baseMap, setBaseMap] = useState('pale');

	useEffect(() => {
		if (userSettings) {
			setOverlay(userSettings.defaultOverlay ? userSettings.defaultOverlay:'slopemap');
			setBaseMap(userSettings.defaultBaseMap ? userSettings.defaultBaseMap:'pale');
			setScope(userSettings.defaultScope === null || userSettings.defaultScope === undefined ? 1:userSettings.defaultScope);
			setGenre(userSettings.defaultGenre === null || userSettings.defaultGenre === undefined ? -1:userSettings.defaultGenre);
			setIsSearchable(userSettings.defaultIsSearchable === null || userSettings.defaultIsSearchable === undefined ? false:userSettings.defaultIsSearchable);
			if(userSettings.defaultScope === 2) {
				setIsSearchable(false);
			}
			setIsCopyable(userSettings.defaultIsCopyable === null || userSettings.defaultIsCopyable === undefined ? true:userSettings.defaultIsCopyable);
		}
	}, []);


	useEffect(() => {
		if (scope === '0') {
			setIsSearchable(false);
			setIsCopyable(false);
		}
		if (scope === '2') {  // scope が 2 (共同編集) のとき
    		setIsSearchable(false);  // isSearchable を強制的に false にする
		}
	}, [scope]);

	const handleSubmit = async () => {
		if (title.length <= 0) {
			setError(t('Enter the title'));
			return;
		}
		if (genre === '-1') {
			setError(t('Please select a genre'));
			return;
		}

		setError('');
		setIsSubmitting(true);
		const collectionPath = `userdata/${fm.auth.currentUser.uid}/projects/`;
		const documentId = CommonUtils.generateUUID();
		const user = fm.auth.currentUser;

		// タグを配列に変換
		let tagsString = tags;
		tagsString = tagsString.replace(/[#＃、　 ]/g, ",").replace(/,+/g, ",").replace(/^,|,$/g, "");
		const tagsArray = tagsString.split(',').map(tag => tag.trim()).filter(tag => tag !== '');
		const permission = parseInt(scope, 10);
		const firedata = {
			title,
			memo,
			tags: tagsArray,
			ownername: myName,
			ownercolor: myColor,
			owneruid: user.uid,
			isfavorite: false,
			overlay: overlay,
			basemap: baseMap,
			genre: parseInt(genre, 10),
			permission: permission,
			issearchable: isSearchable,
			iscopyable: isCopyable,
			createtime: new Date(),
			updatetime: new Date(),
			language:navigator.language,
		};
		try {
			await fm.setDocument(collectionPath, documentId, firedata);
			if(permission > 0 && isSearchable) {
				await fm.writeProjectDataToSearchIndex(title,memo,tagsArray,user.uid,myName,documentId,isSearchable,permission);
			}

			console.log('Document successfully written!');
			onClose();
			window.location.href = `/map/${documentId}_${ user.uid}?is3D=false`;

		} catch (error) {
			console.error('Failed to write document:', error);
			setError(t('Failed to create project'));
		}
		setIsSubmitting(false);
	};

	return (
		<div className="popup">
			<span className="close_button" onClick={onClose}>&times;</span>
			<div className="popup-inner">
				<h2>{t('new_projects')}</h2>
				{error && <div className="error-message" style={{color: 'red'}}>{error}</div>}
				<label>
					{t('Title')}:
					<input type="text" value={title} onChange={(e) => setTitle(e.target.value)} disabled={isSubmitting} />
				</label>
				<label>
					{t('Note')}:
					<textarea value={memo} onChange={(e) => setMemo(e.target.value)} disabled={isSubmitting} />
				</label>
				<label>
					{t('Overlay')}:
					<select value={overlay} onChange={(e) => setOverlay(e.target.value)} disabled={isSubmitting}>
						{Object.entries(CommonUtils.overlays).map(([key, value]) => (
						value.op < 100 && (
							<option key={key} value={key}>
							{value.name}
							</option>
						)
						))}
					</select>
				</label>
				<label>
					{t('Base Map')}:
					<select value={baseMap} onChange={(e) => setBaseMap(e.target.value)} disabled={isSubmitting}>
						{Object.entries(CommonUtils.maps).map(([key, value]) => (
						value.op === 100 && (
							<option key={key} value={key}>
							{value.name}
							</option>
						)
						))}
					</select>
				</label>
				<label>
					{t('Shared range')}:
					<select value={scope} onChange={(e) => setScope(e.target.value)} disabled={isSubmitting}>
						<option value="0">{t('range_private')}</option>
						<option value="1">{t('range_vieweable')}</option>
						<option value="2">{t('range_Joint_editing')}</option>
					</select>
				</label>
				<label>
					{t('Genre')}:
					<select value={genre} onChange={(e) => setGenre(e.target.value)} disabled={isSubmitting}>
						<option value="-1">{t('Select a genre')}</option>
						{[...Array(20)].map((_, index) => (
							<option key={index} value={index.toString()}>
								{t(`Genre_${(index).toString().padStart(2, '0')}`)}
							</option>
						))}
					</select>
				</label>
				<label>
					{t('Tags')}:
					<input 
						type="text" 
						value={tags} 
						onChange={(e) => setTags(e.target.value)} 
						disabled={isSubmitting}
						placeholder={t('Enter tags separated by spaces or commas')}
					/>
				</label>
				<div className="checkbox-wrapper">
					<div className="checkbox-container">
						<input
							type="checkbox"
							id="searchableCheckbox"
							checked={isSearchable}
							onChange={(e) => setIsSearchable(e.target.checked)}
							disabled={isSubmitting || scope === '0' || scope === '2'}  // scope が '2' の場合も disabled
						/>
						<label htmlFor="searchableCheckbox">{t('Searchable')}</label>
						<input
							type="checkbox"
							id="copyableCheckbox"
							checked={isCopyable}
							onChange={(e) => setIsCopyable(e.target.checked)}
							disabled={isSubmitting || scope === '0'}
						/>
						<label htmlFor="copyableCheckbox">{t('Copyable')}</label>
					</div>
				</div>
				<button className='button_b' onClick={onClose} disabled={isSubmitting}>{t('Cancel')}</button>
				<button className='button_y' id="buttonMakeProject" onClick={handleSubmit} disabled={isSubmitting}>
					{isSubmitting ? t('Processing...') : t('Make')}
				</button>
			</div>
		</div>
	);
};

export default AddProjectPopup;